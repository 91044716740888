@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
    font-family: "IBM Plex Mono";
}

.table-wrapper {
    box-shadow: none!important;
}

.art {
    border: 1px solid #eeebee;
}

@media (min-width: 31.25rem) {
    .site-title {
        font-size: 0.7rem !important;
    }
}

 
@media (max-width: 480px) {
    .site-title {
        font-size: 0.8rem !important;
        
    }
}

/* MIXES INDEX */
.mix-container {
  display: grid;
  grid-template-columns: 1fr; /* Single column layout */
  gap: 20px; /* Gap between grid items */
}

.mix-item {
  display: grid;
  grid-template-columns: auto 1fr; /* Image column and details column */
  gap: 20px; /* Gap between image and details */
  background-color: #f9f9f9; /* Light gray background */
  padding: 10px; /* Padding for inner content */
}

.mix-thumbnail img {
  max-width: calc(100% - 10px); /* Set max width for image with margin */
  max-height: 350px; /* Limiting the maximum height to 400px */
  margin-right: 5px; /* 5px margin on the right side of the image */
}

.mix-details {
  display: flex;
  flex-direction: column;
  margin-top: auto; /* Align mix details to the bottom of the mix item */
}

.tag-container {
  margin-top: 10px; /* Add margin between tags and description */
}

.tag {
  display: inline-block;
  background-color: #e9ecef;
  color: #333;
  border-radius: 5px;
  padding: 2px 6px;
  margin-right: 5px;
  font-size: 0.8em;
}

@media (max-width: 767px) {
  .mix-item {
    display: grid;
    grid-template-columns: 1fr; /* Single column layout on mobile */
    gap: 0; /* Remove gap between image and details on mobile */
  }

  .mix-details {
    margin-top: 0; /* Remove margin from top */
  }

  .mix-details h3 {
    margin-top: 0; /* Remove top margin from h3 within mix-details on mobile */
  }
}